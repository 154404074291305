import { Link, type HeadFC, type PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import {
  AspectRatio,
  Badge,
  Box,
  Container,
  Flex,
  HStack,
  Image as ImageChakra,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import "swiper/css"
import "swiper/css/bundle"
import { Autoplay } from "swiper/modules"
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react"
import { HeadSEO } from "../components/head/"
import { IPost } from "../dtos/IPosts.dto"
import { BannersMock } from "../mocks/banners"
import { features } from "../mocks/features"
import { OursLines } from "../mocks/oursLine"
import "../styles/home.css"

type PageContext = {
  posts: IPost[]
}

const IndexPage: React.FC<PageProps> = ({ pageContext }) => {
  const { posts } = pageContext as PageContext
  const [isMounted, setIsMounted] = React.useState(false)
  const swiperRef = React.useRef<SwiperRef>(null)
  const swiperBannerRef = React.useRef<SwiperRef>(null)
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  })
  // const { data } = useCategories()
  // const { data: posts, isLoading } = usePostsByThemeId("49", {
  //   per_page: 3,
  // })
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const [playVideo, setPlayVideo] = React.useState(false)
  const configSwiper = {
    spaceBetween: 10,
    loop: false,
    breakpoints: {
      320: {
        slidesPerView: 1.4,
        spaceBetween: 20,
      },
      455: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2.8,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3.5,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
      1190: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
      1280: {
        slidesPerView: 6,
        spaceBetween: 50,
      },
    },
  }
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [activeBannerSlide, setActiveBannerSlide] = React.useState(0)
  const [firstPost, setFirstPost] = React.useState<IPost | null | undefined>(
    null,
  )
  const [lastPosts, setLastPosts] = React.useState<IPost[]>([])

  React.useEffect(() => {
    if (posts) {
      setFirstPost(posts?.shift())
      setLastPosts(posts?.slice(0, 2) || [])
    }
  }, [posts])

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) return null

  return (
    <>
      {/* <ModalVideo
        isOpen={isOpen}
        onClose={onClose}
        url="https://www.youtube.com/watch?v=c8fqLE0uHhM"
      /> */}
      <main id="home">
        <Box position="relative">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            modules={[Autoplay]}
            autoplay={{ delay: 6000, pauseOnMouseEnter: false }}
            ref={swiperBannerRef}
            onSlideChange={swiper => {
              setActiveBannerSlide(swiper.activeIndex)
            }}
          >
            {BannersMock?.map(banner => (
              <SwiperSlide key={banner.id}>
                <Link
                  to={banner.href}
                  state={{ categoryId: banner?.category_id, productId: banner?.product_id }}
                >
                  <ImageChakra
                    src={isMobile ? banner.image_mobile : banner.image_desk}
                    alt={banner.name}
                    style={{
                      width: "100%",
                    }}
                  />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          <HStack
            mt="3rem"
            position="absolute"
            bottom="10px"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="100"
          >
            {Array.from({ length: BannersMock.length }).map((_, index) => (
              <Box
                as="button"
                bg="#fff"
                opacity={index === activeBannerSlide ? 1 : 0.5}
                width={index === activeBannerSlide ? "1.5rem" : "0.5rem"}
                height="0.5rem"
                borderRadius={index === activeBannerSlide ? "0.25rem" : "50%"}
                key={index}
                onClick={() => {
                  if (
                    swiperBannerRef.current &&
                    swiperBannerRef.current.swiper
                  ) {
                    swiperBannerRef.current.swiper.slideTo(index)
                  }
                }}
              />
            ))}
          </HStack>
        </Box>

        {/* Categorias */}

        <div className="container my-4">
          <div className="category--wrapper">
            <span className="hero--title">Nossas linhas</span>
            <div
              className="row mx-auto d-flex w-100 justify-content-between"
              style={{
                marginTop: "60px",
              }}
            >
              <Swiper {...configSwiper}>
                {OursLines?.map(category => (
                  <SwiperSlide key={category.id}>
                    <Link
                      to={"/nossas-linhas/" + category.path}
                      state={{ categoryId: category?.category_id }}
                    >
                      <div className="slide-container">
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          background={category.color}
                          width="9.16rem"
                          height="9.16rem"
                          borderRadius="50%"
                        >
                          <ImageChakra
                            src={category.image.replace("/site2018/", "/")}
                            alt={category.name}
                          />
                        </Flex>
                        <span className="slide-title">{category.name}</span>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
                <div className="swiper-pagination"></div>
              </Swiper>
            </div>
          </div>
        </div>

        <div
          className="divider"
          style={{
            marginTop: "50px",
            marginBottom: "50px",
          }}
        ></div>



        <div className="container mx-auto">
          <div className="d-flex flex-column">
            <h1 className="content-title">
              Verde Campo | Produtos lácteos
              saudáveis e naturais!
            </h1>
            <p className="content-paragraph mt-3">
              Conheça a linha completa da Verde Campo para uma alimentação saudável e saborosa! Produtos lácteos feitos com leite fresco e ingredientes naturais. Saiba mais!
            </p>
          </div>
          <div className="flex flex-column">
            <div className="row content-card">
              <div className="col-lg-4 col-12 relative d-flex justify-content-center align-items-center mx-0 px-0">
                <StaticImage
                  className="content_01"
                  src="https://admin.verdecampo.com.br/wp-content/uploads/2024/10/content-01.png"
                  alt="CONTEUDO"
                />
              </div>
              <div className="col-lg-8 col-12 relative mx-0 px-0 relative d-flex justify-content-center">
                <div className="d-flex flex-column justify-content-center align-items-center content-card__text mx-0 px-0">
                  <p>
                    A Verde Campo quer <strong>fazer parte da sua rotina.</strong> Seja em um queijo no <strong>café da manhã</strong>, ou em um iogurte no <strong>fim de tarde</strong>, queremos estar junto a você.
                  </p>
                  <p>
                    <strong>Tanto com a família</strong>, para aquela refeição coletiva, com <strong>nossos queijos, requeijões e iogurtes</strong>, como na <strong>correria do dia a dia</strong>, onde <strong>você só precisa abrir</strong> a tampinha do <strong>nosso Natural Whey</strong>.
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <span className="alternative-title">
                Prezamos por alta qualidade
              </span>
              <h1 className="content-title__large">
                Seja qual for o momento do dia, tem um produto Verde Campo que vai te acompanhar!
              </h1>
            </div>
            <div className="row content-card">
              <div className="col-lg-5 col-12 relative d-flex justify-content-center align-items-center mx-0 px-0">
                <StaticImage
                  className="content_01"
                  src="https://admin.verdecampo.com.br/wp-content/uploads/2024/10/content-02.png"
                  alt="CONTEUDO 02"
                />
              </div>
              <div className="col-lg-7 col-12 relative mx-0 px-0 relative d-flex justify-content-center">
                <div className="d-flex flex-column justify-content-center align-items-center content-card__text mx-0 px-0">
                  <p>Todos os produtos <strong>Verde Campo</strong> são produzidos com leite fresco, vindos de <strong>fazendas certificadas</strong>. Isso porque, para nós, <strong>a qualidade começa lá na extração do leite</strong>.</p>

                  <p>Assim, contamos com os <strong>melhores fornecedores</strong> e <strong>rígidos processos de produção</strong> para que possamos oferecer <strong>o melhor produto à sua mesa</strong>. E é por isso, que <strong>nos destacamos com pioneirismo em diversas frentes</strong>.</p>

                  <p>Como por exemplo, na criação da <strong>primeira linha de lácteos sem lactose do Brasil, a (Lacfree), em 2011</strong>, que são produtos com a <strong>tecnologia triplo zero: zero lactose, zero adição de açúcar e zero gordura</strong>. Fruto de <strong>estudo e melhorias</strong> em nosso processo de produção!</p>

                </div>
              </div>
            </div>


            <div className="row content-card flex-column-reverse flex-lg-row">

              <div className="col-lg-7 col-12 relative d-flex justify-content-center mx-0 px-0">
                <div className="d-flex flex-column justify-content-center content-card__text">
                  <h3 className="content-03-title">
                    Variedade de produtos
                  </h3>
                  <p>
                    Por aqui, você encontra <strong>uma grande variedade de produtos lácteos</strong>. Todos em versões que <strong>se adequam à sua rotina</strong>, como citamos antes.
                  </p>
                  <p>
                    Por isso, além de manter nosso <strong>padrão de qualidade</strong>, nossa equipe está sempre <strong>estudando possibilidades</strong> de <strong>proporcionar variedade</strong> de produtos <strong>que se encaixem no seu dia a dia</strong>.
                  </p>
                  <p>
                    <strong>Afinal</strong>, sabemos como <strong>pode ser trabalhoso manter a alimentação saudável</strong> em rotinas aceleradas. Se os <strong>produtos Verde Campo estiverem ao seu lado</strong>, esse <strong>não será mais um problema</strong>!
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-12 relative d-flex justify-content-center align-items-center mx-0 px-0">
                <StaticImage
                  className="content_01"
                  src="https://admin.verdecampo.com.br/wp-content/uploads/2024/10/content-03.png"
                  alt="CONTEUDO"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Video */}
        {!playVideo && (
          <Flex
            alignItems="center"
            justifyContent="center"
            paddingTop="2.83rem"
            paddingBottom="5rem"
            px="1rem"
            position="relative"
            onClick={() => setPlayVideo(!playVideo)}
          >
            <StaticImage src="../images/flu.svg" alt="LACFREE" />
            <Box
              cursor="pointer"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              <StaticImage src="../images/play.svg" alt="LACFREE" />
            </Box>
          </Flex>
        )}

        {playVideo && (
          <Container
            maxW="container.xl"
            paddingTop="2.83rem"
            paddingBottom="5rem"
          >
            <AspectRatio borderRadius={25} ratio={16 / 9}>
              <iframe
                style={{ borderRadius: 25 }}
                src="https://www.youtube.com/embed/c8fqLE0uHhM?autoplay=1&disablekb=1&fs=1&showinfo=1&modestbranding=0&color=red"
                title="Verde Campo: Saudável é ser feliz, ser livre, ser você."
              />
            </AspectRatio>
          </Container>
        )}
        {/*  */}

        <div className="position-relative">
          <div className="container section-beneficios ">
            <div>
              <div className="position-absolute fundo-banner_01">
                <svg
                  width="538"
                  height="913"
                  viewBox="0 0 538 913"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M414.34 25.2665C467.687 56.0224 474.888 126.122 541 129.222L541 908.302C541 908.302 469.505 918.46 426.856 908.302C361.488 892.733 362.933 843.237 299.195 823.961C238.64 805.647 185.656 844.415 133.486 814.154C93.7802 791.123 101.743 758.962 76.9142 725.497C51.8413 691.703 21.9895 679.313 5.82436 642.332C-35.9444 546.778 161.594 502.396 133.486 403.822C122.407 364.971 86.823 351.072 76.9142 312.027C51.819 213.142 76.9143 146.483 196.065 59.3954C270.686 4.85453 332.146 -22.1207 414.34 25.2665Z"
                    fill="#59B36C"
                  />
                </svg>
              </div>
              <div className="row mx-auto share-benefits">
                <div className="col-md-4 px-0">
                  <Link to="/nossa-historia">
                    <StaticImage
                      className="banner_01"
                      src="../images/image-beneficios.png"
                      alt="LACFREE"
                    />
                  </Link>
                </div>
                <div className="col-md-7">
                  <Box
                    alignItems={{
                      base: "center",
                      md: "flex-start",
                    }}
                    px={{
                      base: "1rem",
                      md: "2.06rem",
                    }}
                  >
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={1}
                      ref={swiperRef}
                      modules={[Autoplay]}
                      autoplay={{ delay: 6000, pauseOnMouseEnter: false }}
                      onSlideChange={swiper => {
                        setActiveSlide(swiper.activeIndex)
                      }}
                    >
                      {features.map((_, index) => (
                        <SwiperSlide key={index}>
                          <Stack
                            spacing={6}
                            alignItems={{
                              base: "center",
                              md: "flex-start",
                            }}
                            key={index}
                          >
                            <Box>
                              <StaticImage
                                src="../images/good_pratices.svg"
                                alt="Boas Práticas"
                              />
                            </Box>
                            <Text
                              as="h2"
                              fontSize={{ base: "1.5rem", md: "2.9rem" }}
                              fontWeight="bold"
                              color="#00615C"
                            >
                              {_.title}
                            </Text>
                            <Text
                              as="h6"
                              color="#00615C"
                              fontSize={{ base: "1rem", md: "1.5rem" }}
                            >
                              {_.description}
                            </Text>
                            <div className="d-flex flex-row mt-3">
                              <a
                                href="/nossa-historia"
                                className="btn btn-card-line px-3 px-md-4 mr-2"
                              >
                                Nossa História
                              </a>
                            </div>
                          </Stack>
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    <HStack mt="3rem">
                      {Array.from({ length: features.length }).map(
                        (_, index) => (
                          <Box
                            as="button"
                            bg="#00615C"
                            opacity={index === activeSlide ? 1 : 0.5}
                            width={index === activeSlide ? "1.5rem" : "0.5rem"}
                            height="0.5rem"
                            borderRadius={
                              index === activeSlide ? "0.25rem" : "50%"
                            }
                            key={index}
                            onClick={() => {
                              if (
                                swiperRef.current &&
                                swiperRef.current.swiper
                              ) {
                                swiperRef.current.swiper.slideTo(index)
                              }
                            }}
                          />
                        ),
                      )}
                    </HStack>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Posts */}
        <div className="container posts-blog">
          <Flex
            alignItems="center"
            justifyContent="center"
            width="100%"
            py="3rem"
          >
            <Flex
              height="100%"
              gap={4}
              // maxW="76.5rem"
              flexDir={{
                base: "column",
                md: "row",
              }}
            >
              {firstPost && (
                <Box
                  maxW="31rem"
                  maxH="35.25rem"
                  width="100%"
                  height="100%"
                  bg="#DEE6E4"
                  borderRadius="10px"
                >
                  <Link to={"/blog/" + firstPost?.slug}>
                    <Box position="relative">
                      <ImageChakra
                        src={firstPost?.capa_da_postagem?.guid}
                        alt="Placeholder"
                        objectFit="cover"
                        width="100%"
                        maxH="19.8rem"
                        borderRadius="10px 10px 0 0"
                      />
                      <Badge
                        position="absolute"
                        top="1.5rem"
                        left="1.5rem"
                        background="#FF8674"
                        padding="0.2rem 1rem"
                        borderRadius="10px"
                        color="#fff"
                      >
                        {firstPost?.categoria}
                      </Badge>
                    </Box>
                  </Link>
                  <Stack padding="24px">
                    <Text
                      as="h2"
                      fontSize="1.125rem"
                      color="#00615C"
                      fontWeight="700"
                      lineHeight="1.4rem"
                    >
                      {firstPost?.titulo_da_postagem}
                    </Text>
                    <Text as="p" fontSize="1rem">
                      {firstPost?.resumo}
                    </Text>
                    <a href={"/blog/" + firstPost?.slug}>Ler mais</a>
                  </Stack>
                </Box>
              )}

              {/* Skeleton */}

              {lastPosts.length > 0 && (
                <Stack width="100%">
                  {lastPosts?.map((post, index) => (
                    <Flex
                      width="100%"
                      height="100%"
                      bg="#DEE6E4"
                      borderRadius="10px"
                      maxW={{
                        base: "31rem",
                        md: "100%",
                      }}
                      maxH={{
                        base: "100%",
                        md: "16.875rem",
                      }}
                      padding={{
                        base: "0",
                        md: "1.5rem",
                      }}
                      flexDir={{
                        base: "column",
                        md: "row",
                      }}
                    >
                      <Link to={"/blog/" + firstPost?.slug}>
                        <ImageChakra
                          src={post?.capa_da_postagem?.guid}
                          alt="Placeholder"
                          objectFit="cover"
                          width="100%"
                          borderRadius="16px"
                          maxW={{
                            base: "100%",
                            md: "13rem",
                          }}
                          maxH="19.8rem"
                        />
                      </Link>
                      <Stack spacing={3} padding="24px" width="100%">
                        <Box>
                          <Badge
                            background={index === 0 ? "#FFB600" : "#00615C"}
                            padding="0.2rem 1rem"
                            borderRadius="10px"
                            color="#fff"
                          >
                            {post.categoria}
                          </Badge>
                        </Box>
                        <Text
                          as="h2"
                          fontSize={{ base: "1.125rem", md: "1rem" }}
                          color="#00615C"
                          fontWeight="700"
                          lineHeight="1.4rem"
                        >
                          {post?.titulo_da_postagem}
                        </Text>
                        <Text noOfLines={2} as="p" fontSize="0.875rem">
                          {post?.resumo}
                        </Text>
                        <a href={"/blog/" + firstPost?.slug}>Ler mais</a>
                      </Stack>
                    </Flex>
                  ))}
                </Stack>
              )}
            </Flex>
          </Flex>
        </div>
        {/*  */}

        {/* <div className="p-0 compre-natural-whey hidden">
          <div className="row mx-0">
            <div className="col-md-5 col-12 p-0">
              <a
                href="https://loja.mercadolivre.com.br/verde-campo"
                target="_blank"
              >
                <StaticImage
                  className="p-0 m-0"
                  src="../images/compre-natural-whey.png"
                  alt="Natural Whey"
                  quality={95}
                  formats={["png"]}
                />
              </a>
            </div>
            <div className="col-md-7 p-0">
              <div
                className="d-flex flex-column h-100 justify-content-center p-3 p-md-5"
                style={{ maxWidth: "700px" }}
              >
                <span className="h1">
                  COMPRE AGORA O SEU SHAKE NATURAL WHEY
                </span>
                <span className="paragraph ">
                  Venha conhecer a loja Verde Campo! Agora ficou muito mais fácil adquirir toda a linha de Shake Natural Whey, e o melhor: com preços especiais! Não perca essa oportunidade.
                </span>
                <div className="d-flex flex-row mt-3">
                  <a
                    href="https://loja.verdecampo.com.br/c/shakes?s=SSS,&categories=SHAKES,&order_by=lowest_price"
                    target="_blank"
                    className="btn btn-card-line orange px-5 px-md-5 mr-2"
                  >
                    Ver na loja
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </>
  )
}

export default IndexPage

export const Head: HeadFC = () =>
  HeadSEO(
    "Verde Campo | Produtos lácteos saudáveis e naturais!",
    "Conheça a linha completa da Verde Campo para uma alimentação saudável e saborosa! Produtos lácteos feitos com leite fresco e ingredientes naturais. Saiba mais!",
    ""
  )