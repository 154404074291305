import { v4 as uuidv4 } from "uuid"

export const BannersMock = [
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2025/01/24_11_VC_BannerIogurte_1920x535.jpg",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2025/01/24_11_VC_BannerIogurte_512x512.jpg",
    name: "Linha Natural - Verde Campo",
    href: "/nossas-linhas/natural-whey",
    category_id: "64",
    product_id: "68"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/10/24_08_VC_LançamentoSobremesaSite_1920x535-1.jpg",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/10/24_08_VC_LançamentoSobremesaSite_512x512-1.jpg",
    name: "Linha Natural - Verde Campo",
    href: "/nossas-linhas/sentidos",
    category_id: "75",
    product_id: "75"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/09/24_09_VC_LançamentoKefirSite_Intitucional_Desktop.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/09/24_09_VC_LançamentoKefirSite_Intitucional_Mobile.png",
    name: "Linha Natural - Verde Campo",
    href: "/nossas-linhas/corpo-e-mente",
    category_id: "6",
    product_id: "76"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/08/24_08_VC_AçõesEcommerceSite_Intitucional.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/08/24_08_VC_AçõesEcommerceSite_Intitucional_512x512.png",
    name: "Linha Natural - Verde Campo",
    href: "/nossas-linhas/natural-whey",
    category_id: "11",
    product_id: "59"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/04/24_01_VC_IogurteNaturalWhey_1920x535.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/03/24_01_VC_IogurteNaturalWhey_860x860.png",
    name: "Linha Natural - Verde Campo",
    href: "/nossas-linhas/natural-whey",
    category_id: "11",
    product_id: "34"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/04/24_01_VC_Colherável_Desktop.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2024/03/24_01_VC_Colherável_860x860.png",
    name: "Colherável - Verde Campo",
    href: "/nossas-linhas/natural-whey",
    category_id: "11",
    product_id: "59"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2023/10/23_10_VC_Shake14_1920x535_Institucional.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2023/10/23_10_VC_Shake14_512x512_Institucional.png",
    name: "Novos Shakes - Verde Campo",
    href: "/nossas-linhas/natural-whey",
    category_id: "11",
    product_id: "31"
  },
  {
    id: uuidv4(),
    image_desk:
      "https://admin.verdecampo.com.br/wp-content/uploads/2023/09/23_06_VC_OndeEncontrar_Banner_1920x535.png",
    image_mobile:
      "https://admin.verdecampo.com.br/wp-content/uploads/2023/09/23_06_VC_OndeEncontrar_Banner_512x512.png",
    name: "Onde Econtrar - Verde Campo",
    href: "/onde-comprar"
  },
]
